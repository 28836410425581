import ApiDefault from "../../ApiDefault";

export default function ApiOutboundOrders() {
    const apiDefault = ApiDefault("v2/stocks/outbound-orders");

    const details = async (id) => {
        return await apiDefault.api
            .get(`/${apiDefault.url}/manager/details/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const item = async (outboundOrderId, id, data) => {
        return await apiDefault.api
            .patch(`/${apiDefault.url}/${outboundOrderId}/item/${id}`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const list = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/manager/list?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const receipt = async (files, layout) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/import-files/${layout}`, files, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const processPending = async (condition) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/process-files`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const makeDelivery = async (id) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/manager/delivery/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const processFileManual = async (id) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/force-order/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const referencesPending = async (condition, scheduled = false) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/manager/link-inbound-orders${scheduled ? "?scheduled" : ""}`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const scheduleFile = async (id, data) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/process-files/schedule/${id}`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        details,
        item,
        list,
        makeDelivery,
        processFileManual,
        processPending,
        receipt,
        referencesPending,
        scheduleFile,
    };
}
