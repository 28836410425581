import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { PageControl, TabSheet, InputText, Grade, GradeOpcoes } from "../../../../components";

import { Grid, Button, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { AppContext } from "../../../../App";

import InputDropDowList from "../../../../components/inputs/input.dropdowlist2";

import FormatsUtils from "../../../../services/FormatsUtils";
import QueryCities from "../../query/cities";
import ApiMembers from "../../../../api/basics/members/ApiMembers";
import { AttributesModal } from "../../../../components/attributes";
import QueryIntegrations from "../../../system/query/integrations";
import { TypeNature } from "../utils";
import { AppOptionsContext } from "../../../../components/main/main";
import QuerySuppliersGroups from "../../query/suppliersGroups";
import { ValidateMembers, ValidateMembersSuppliers } from "../validates";
import MembersRelationshipsChange from "../relationshipsChange";
import InputLookupChartAccounts from "../../../accounting/lookups/chartAccounts";

const initialValues = {
    name: "",
    trading_name: "",
    country_id: 1058,
    type_member: "",
    type_nature: "natural_person",
    type_tax_regime: "",
    document: "",
    city_id: 0,
    city: {},
    zipcode: "",
    address: "",
    address_number: "",
    district: "",
    address_complement: "",
    fone: "",
    email: "",
    attributes: {},
};

const initialValuesIntegrations = {
    integration_id: 0,
    integration: {},
    attributes: {},
};

const initialValuesBranch = {
    chart_account: {},
    chart_account_id: 0,
    inactivate_at: null,
};

const REVERSE = "_reverse";

export default function MembersChange({ id, onLoadRecord, onFinalize, attributes, showOptions, types }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexIntegracoes, setTabIndexIntegracoes] = useState(0);

    const [member, setMember] = useState(initialValues);
    const [openBranchAttributes, setOpenBranchAttributes] = useState(false);
    const [openMemberIntegrations, setOpenMemberIntegrations] = useState(false);

    const [openSupplierAttributes, setOpenSupplierAttributes] = useState(false);

    // Associates
    const [branch, setBranch] = useState(initialValuesBranch);
    const [supplier, setSupplier] = useState({});
    const [customer, setCustomer] = useState({});

    const [isBranch, setIsBranch] = useState(false);
    const [isSupplier, setIsSupplier] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);

    const [membersRelationships, setMembersRelationships] = useState([]);

    const [memberIntegrations, setMemberIntegrations] = useState([]);
    const [memberIntegration, setMemberIntegration] = useState(initialValuesIntegrations);

    const findAttributes = useCallback((entity, _attributes) => {
        const attributesCustom = (_attributes || []).find((rec) => rec.entity === entity);
        if (attributesCustom) {
            return attributesCustom.values;
        }
        return [];
    });

    const typeMember = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "members" && rec.field === "type_member")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || []
        );
    }, [types]);

    const typeTaxRegime = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "members" && rec.field === "type_tax_regime")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || []
        );
    }, [types]);

    const relationshipsTypes = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "members_relationships" && rec.field === "relation_type")
                ?.values?.map((rec) => {
                    return rec;
                }) || []
        );
    }, [types]);

    const branchsAttributes = useMemo(() => {
        return findAttributes("members_branchs", attributes);
    }, [attributes]);

    const supplierAttributes = useMemo(() => {
        return findAttributes("members_suppliers", attributes);
    }, [attributes]);

    const integrationsAttributes = useMemo(() => {
        const attributesCustom = attributes.filter((rec) => rec.entity === "integrations");
        if (attributesCustom.length) {
            return attributesCustom;
        }
        return [];
    }, [attributes]);

    const relationshipsAttrs = useMemo(() => {
        const attributesCustom = attributes.filter((rec) => rec.entity === "members_relationships");
        if (attributesCustom.length) {
            return attributesCustom;
        }
        return [];
    }, [attributes]);

    const attributesByIntegrationId = useCallback(
        (integration) => {
            if (!integration || !integration.id) {
                return [];
            }

            const field = `$${integration.type_integration}$${integration.type_auth}`;
            const attr = integrationsAttributes.find((rec) => rec.field === field);

            if (attr) {
                return attr.values;
            }

            return [];
        },
        [integrationsAttributes]
    );

    const metaDataIntegrations = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsIntegrations} opcoes={options} />
                    </span>
                );
            },
        },
        {
            label: "Integração",
            field: "integration_id",
        },
        {
            label: "Nome da Integração",
            render: (record) => {
                return <>{record.integration?.name}</>;
            },
        },
        {
            label: "Parametros",
            render: (record) => {
                return <>{Object.keys(record.attributes || {}).length}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(member, isBranch, branch, isCustomer, customer, isSupplier, supplier, memberIntegrations, membersRelationships)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, member, isBranch, branch, isCustomer, customer, isSupplier, supplier, memberIntegrations, id, membersRelationships]);

    const onClickOptionsIntegrations = async (record, type, index) => {
        switch (type) {
            case "edit":
                setMemberIntegration({ ...record });
                break;
            case "remove":
                const memberIntegrationsCustom = [...memberIntegrations];
                memberIntegrationsCustom.splice(index, 1);
                setMemberIntegration(memberIntegrationsCustom);
                break;
            default:
                break;
        }
    };

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const result = await ApiMembers().apiDefault.findId(id, {
                associates: [
                    "attributes",
                    "city",
                    "branch",
                    "branch.chart_account",
                    "supplier",
                    "supplier.group",
                    "customer",
                    "documents",
                    "integrations",
                    "integrations.integration",
                    "relationships",
                    "relationships.member_relation",
                    "relationships_reverse",
                    "relationships_reverse.member",
                ],
            });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            // Branch
            const branchCustom = result.data.branch || initialValuesBranch;
            if (!branchCustom.chart_account) {
                branchCustom.chart_account = {};
            }
            setBranch(branchCustom);

            setMember(result.data);
            setCustomer(result.data.customer || {});
            setSupplier(result.data.supplier || {});
            setMemberIntegrations(result.data.integrations || []);

            setIsBranch(Object.keys(result.data.branch || {}).length > 0 && result.data.branch?.inactivate_at === null);
            setIsCustomer(Object.keys(result.data.customer || {}).length > 0 && result.data.customer?.inactivate_at === null);
            setIsSupplier(Object.keys(result.data.supplier || {}).length > 0 && result.data.supplier?.inactivate_at === null);

            // Relationships
            const membersRelationshipsCustom = [];
            if (result.data.relationships?.length) {
                result.data.relationships.forEach((rec) => {
                    membersRelationshipsCustom.push({
                        member_id: rec.member_relation_id,
                        member: rec.member_relation,
                        relation_type: rec.relation_type,
                        attributes: rec.attributes,
                    });
                });
            }
            if (result.data.relationships_reverse?.length) {
                result.data.relationships_reverse.forEach((rec) => {
                    membersRelationshipsCustom.push({
                        member_id: rec.member_id,
                        member: rec.member,
                        relation_type: rec.relation_type + REVERSE,
                        attributes: rec.attributes,
                    });
                });
            }

            setMembersRelationships(membersRelationshipsCustom);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setMember(initialValues);
        setBranch(initialValuesBranch);
        setCustomer({});
        setSupplier({});
        setMembersRelationships([]);
        setMemberIntegrations([]);
        setMemberIntegration(initialValuesIntegrations);

        setIsBranch(false);
        setIsSupplier(false);
        setIsCustomer(false);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = async (member, isBranch, branch, isCustomer, customer, isSupplier, supplier, memberIntegrations, relationships) => {
        const memberApply = {
            name: member.name,
            trading_name: member.trading_name || null,
            country_id: 1058,
            type_member: member.type_member,
            type_nature: member.type_nature,
            type_tax_regime: member.type_tax_regime,
            document: member.document,
            city_id: member.city.id,
            zipcode: member.zipcode,
            address: member.address,
            address_number: member.address_number,
            district: member.district,
            address_complement: member.address_complement,
            fone: member.fone,
            email: member.email,
            attributes: {},
            branch: undefined,
            supplier: undefined,
            customer: undefined,
            integrations: [],
            relationships: [],
            relationships_reverse: [],
        };

        // Branch
        if (isBranch) {
            memberApply.branch = {
                chart_account_id: branch.chart_account?.id || null,
                inactivate_at: null,
                attributes: branch.attributes,
            };
        } else {
            if (member.branch?.id) {
                memberApply.branch = {
                    inactivate_at: FormatsUtils().dateAnsi(new Date()),
                    attributes: branch.attributes,
                };
            }
        }

        // Customer
        if (isCustomer) {
            memberApply.customer = {
                inactivate_at: null,
            };
        } else {
            if (member.customer?.id) {
                memberApply.customer = {
                    inactivate_at: FormatsUtils().dateAnsi(new Date()),
                };
            }
        }

        // Supplier
        if (isSupplier) {
            memberApply.supplier = {
                inactivate_at: null,
                supplier_group_id: supplier.group?.id || 0,
                attributes: supplier.attributes,
            };
        } else {
            if (member.supplier?.id) {
                memberApply.supplier = {
                    inactivate_at: FormatsUtils().dateAnsi(new Date()),
                    attributes: supplier.attributes,
                };
            }
        }

        // Integrations
        memberApply.integrations = (memberIntegrations || []).map((rec) => {
            return {
                integration_id: rec.integration_id || rec.integration?.id,
                attributes: rec.attributes,
            };
        });

        // Relationships
        memberApply.relationships = (relationships || [])
            .filter((rec) => !String(rec.relation_type).endsWith(REVERSE))
            .map((rec) => {
                return {
                    relation_type: rec.relation_type,
                    member_relation_id: rec.member_id,
                    attributes: rec.attributes || null,
                };
            });

        memberApply.relationships_reverse = (relationships || [])
            .filter((rec) => String(rec.relation_type).endsWith(REVERSE))
            .map((rec) => {
                return {
                    relation_type: String(rec.relation_type).replace(REVERSE, ""),
                    member_id: rec.member_id,
                    attributes: rec.attributes || null,
                };
            });

        // Validate
        const typeMemberValues = typeMember.map((rec) => rec.value);
        const validate = await ApiMembers().apiDefault.validate(ValidateMembers(typeMemberValues), memberApply);
        if (!validate.status) {
            showMessageError(validate.message);
            return;
        }

        // Validate Suppliers
        if (isSupplier) {
            const validate = await ApiMembers().apiDefault.validate(ValidateMembersSuppliers(), memberApply.supplier);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }
        }

        setLoading(true);
        try {
            let response = {};

            if (id) {
                response = await ApiMembers().apiDefault.update(id, memberApply);
            } else {
                response = await ApiMembers().apiDefault.creates(memberApply);
            }

            if (!response.status) {
                showMessageError(response.message);
                return;
            }

            showMessageInfo("Registro atualizado com sucesso");

            onClearRecord();
            onFinalize(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <InputDropDowList
                        value={member.type_nature}
                        onChangeValue={(e) => setMember({ ...member, type_nature: e.target.value })}
                        data={TypeNature}
                        label={"Natureza"}
                    ></InputDropDowList>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputDropDowList
                        value={member.type_member}
                        onChangeValue={(e) => setMember({ ...member, type_member: e.target.value })}
                        data={typeMember}
                        label={"Tipo Documento Identificação"}
                    ></InputDropDowList>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText
                        required
                        label="Documento Identificação"
                        value={member.document}
                        onChangeValue={(e) => setMember({ ...member, document: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText required label="Nome" value={member.name} onChangeValue={(e) => setMember({ ...member, name: e.target.value })} />
                </Grid>
                <Grid item xs={6}>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={isBranch} onChange={(e) => setIsBranch(e.target.checked)} name="checkedA" />}
                            label="Estabelecimento"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isCustomer} onChange={(e) => setIsCustomer(e.target.checked)} name="checkedA" />}
                            label="Cliente"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isSupplier} onChange={(e) => setIsSupplier(e.target.checked)} name="checkedA" />}
                            label="Fornecedor/Prestador"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "5px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Dados Básicos">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText label="CEP" value={member.zipcode} onChangeValue={(e) => setMember({ ...member, zipcode: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText label="Endereço" value={member.address} onChangeValue={(e) => setMember({ ...member, address: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label="Número"
                                value={member.address_number}
                                onChangeValue={(e) => setMember({ ...member, address_number: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText label="Bairro" value={member.district} onChangeValue={(e) => setMember({ ...member, district: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Complemento"
                                value={member.address_complement}
                                onChangeValue={(e) => setMember({ ...member, address_complement: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText label="País" disabled value={"BRASIL"} />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <QueryCities country={1058} label="Cidade" value={member.city} onChangeValue={(data) => setMember({ ...member, city: data })} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText label="Contato - Fone" value={member.fone} onChangeValue={(e) => setMember({ ...member, fone: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText label="Contato - E-mail" value={member.email} onChangeValue={(e) => setMember({ ...member, email: e.target.value })} />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label="Dados Entidade" visible={member.type_nature === "legal_entity"}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputDropDowList
                                value={member.type_tax_regime}
                                onChangeValue={(e) => setMember({ ...member, type_tax_regime: e.target.value })}
                                data={typeTaxRegime}
                                label={"Regime Tributário"}
                            ></InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                disabled={member.type_nature === "natural_person"}
                                label="Fantasia"
                                value={member.trading_name}
                                onChangeValue={(e) => setMember({ ...member, trading_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label={"Documentos"}>Lista de documentos</TabSheet>

                <TabSheet label={"Estabelecimento"} visible={isBranch}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputLookupChartAccounts
                                required={true}
                                label={`Plano de Contas`}
                                value={branch.chart_account}
                                onChangeValue={(data) => {
                                    setBranch({ ...branch, chart_account: data });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenBranchAttributes(true);
                                }}
                            >
                                Parâmetros
                            </Button>
                        </Grid>
                    </Grid>
                    <AttributesModal
                        open={openBranchAttributes}
                        setOpen={(open) => setOpenBranchAttributes(open)}
                        attributes={branchsAttributes}
                        value={branch.attributes || {}}
                        onChangeValue={(data) => setBranch({ ...branch, attributes: data })}
                    />
                    <PageControl style={{ marginTop: "5px" }} tabindex={tabIndexIntegracoes} onchangetab={(index) => setTabIndexIntegracoes(index)}>
                        <TabSheet label="Integrações">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <QueryIntegrations
                                        label="Integração"
                                        value={memberIntegration.integration}
                                        onChangeValue={(data) => setMemberIntegration({ ...memberIntegration, integration: data })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                                <Grid item xs={12} sm={12}>
                                    <Button
                                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setMemberIntegration(initialValuesIntegrations)}
                                    >
                                        Nova Integração
                                    </Button>
                                    <Button
                                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={!memberIntegration.integration?.id}
                                        onClick={() => {
                                            setOpenMemberIntegrations(true);
                                        }}
                                    >
                                        Parâmetros
                                    </Button>
                                    <Button
                                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const indexMemberIntegration = memberIntegrations.findIndex(
                                                (rec) => rec.integration_id === memberIntegration.integration.id
                                            );
                                            const memberIntegrationsCustom = [...memberIntegrations];

                                            if (indexMemberIntegration === -1) {
                                                memberIntegration.integration_id = memberIntegration.integration.id;
                                                memberIntegrationsCustom.push(memberIntegration);
                                            } else {
                                                memberIntegrationsCustom[indexMemberIntegration] = memberIntegration;
                                            }

                                            setMemberIntegrations(memberIntegrationsCustom);
                                            setMemberIntegration(initialValuesIntegrations);
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                            {memberIntegrations?.length > 0 && (
                                <Grid container spacing={1}>
                                    <Grade dataSource={memberIntegrations} metaData={metaDataIntegrations} disablepagination={true} />
                                </Grid>
                            )}
                            <AttributesModal
                                open={openMemberIntegrations}
                                setOpen={(open) => setOpenMemberIntegrations(open)}
                                attributes={attributesByIntegrationId(memberIntegration.integration)}
                                value={memberIntegration.attributes}
                                onChangeValue={(data) => setMemberIntegration({ ...memberIntegration, attributes: data })}
                            />
                        </TabSheet>
                    </PageControl>
                </TabSheet>
                <TabSheet label={"Cliente"} visible={isCustomer}>
                    Dados do cliente
                </TabSheet>
                <TabSheet label={"Fornecedor/Prestador"} visible={isSupplier}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <QuerySuppliersGroups
                                title={`Consulta Grupos Fornecedores`}
                                value={supplier.group}
                                onChangeValue={(data) => setSupplier({ ...supplier, group: data })}
                                disabled={!isSupplier}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenSupplierAttributes(true);
                                }}
                            >
                                Parâmetros
                            </Button>
                        </Grid>
                    </Grid>
                    <AttributesModal
                        open={openSupplierAttributes}
                        setOpen={(open) => setOpenSupplierAttributes(open)}
                        attributes={supplierAttributes}
                        value={supplier.attributes || {}}
                        onChangeValue={(data) => setSupplier({ ...supplier, attributes: data })}
                    />
                </TabSheet>
                <TabSheet label={"Relacionamentos"}>
                    <MembersRelationshipsChange
                        relationTypes={relationshipsTypes}
                        relationAttrs={relationshipsAttrs}
                        membersRelationships={membersRelationships}
                        setMembersRelationships={setMembersRelationships}
                    />
                </TabSheet>
            </PageControl>
        </>
    );
}
