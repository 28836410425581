import React, { useState, useEffect } from "react";

import { InputText } from "../../../../components";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";

const INITIAL_DATA = {
    purchase_number: "",
    purchase_number_item: "",
    id: 0,
    outbound_order_id: 0,
};

export default function OutboundOrdersChangeItem({ itemOrders, setItemOrders, open, setOpen }) {
    const [data, setData] = useState(INITIAL_DATA);

    useEffect(() => {
        setData({
            purchase_number: itemOrders.purchase_number,
            purchase_number_item: itemOrders.purchase_number_item,
            id: itemOrders.id,
            outbound_order_id: itemOrders.outbound_order_id,
        });
    }, [itemOrders]);

    return (
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>
                Edição Item <br />
                {itemOrders.item_id}-{itemOrders.item_name}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <InputText
                            label="Pedido"
                            value={data.purchase_number || ""}
                            onChangeValue={(e) => setData({ ...data, purchase_number: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                    <Grid item xs={12} sm={12}>
                        <InputText
                            label="Pedido Item"
                            value={data.purchase_number_item || ""}
                            onChangeValue={(e) => setData({ ...data, purchase_number_item: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setItemOrders(data.outbound_order_id, data.id, {
                                    purchase_number: data.purchase_number,
                                    purchase_number_item: data.purchase_number_item,
                                });
                                setOpen(false);
                            }}
                        >
                            Atualizar Item
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
